import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractServerInteractionService, combineHeadersOptions,
  commonErrorHandlingDisabled
} from '../abstract/abstract.server.interaction.service';
import { Observable, catchError, of } from 'rxjs';

import {
  IOnboardingTaskModel,
  IRecruitRotMainViewModel
} from './interfaces';
import { AppService } from '../../../../app.service';

@Injectable()
export class TasksService extends AbstractServerInteractionService {
  protected get url(): {
    recruit: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    complete: Function,
  } {
    return this.appService.getApiUrls().tasks;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public getRecruitTasks(): Observable<IRecruitRotMainViewModel | any> {
    return this.http.get(this.url.recruit, {headers: combineHeadersOptions(commonErrorHandlingDisabled)})
      .pipe(catchError((error) => of({ error })));
  }

  public completeTask(taskId): Observable<IOnboardingTaskModel | any> {
    return this.http.post(this.url.complete(taskId), null);
  }
}
